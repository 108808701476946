<template>
    <div class="all">

        <div class="case">
            <div class="case_red">
            </div>
            <div class="case_bg">
                <div class="case_bg1"></div>
            </div>
            <div class="case_logo">
                <img src="@/assets/images/Case_study_title.png" width="200"/>
            </div>
<!--            <div class="case_return"  v-if="isMobile"   @click="back()">-->
<!--                <img src="@/assets/images/Close_X.png" width="30"/>-->
<!--            </div>-->
            <div class="case_title">
                ERP<br />
                IMPLEMENTATION
            </div>
            <div class="case_intro">Through a series of M&As, the group company grew to consist of 5 entities with 4 different ERPs. The unique workflows and lack of reporting and analysis functions in each ERP resulted in lack of transparency for profitability at the group level. SAP Business by Design was implemented for the group to map uniform business process and provide KPIs for corporate management and planning.</div>

            <caseList :list="list" style="position: relative;"></caseList>

        </div>
    </div>

</template>

<script>
import caseList from "../common/case/caseList"
import {ref} from "vue";
import {useScreen} from "@/js/use/screen";
import {usePart} from "@/js/use/part";


export default {
    components: {
        caseList
    },
    mounted() {
    },
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    methods:{
        back(){
            this.$router.back()
        },
    },
    data() {
        return {
            list:[
                {
                    img:require('@/assets/images/Hill.png'),
                    title:'CHALLENGE',
                    list:[
                        'There was immense challenge in migrating data from 4 operating ERPs, the sheer volume of the data combined with different data structures made the migration extremely difficult. ',
                        'Business processes from 5 entities must be streamlined and integrated in the ERP implementation process. ',
                        'The project had a tight deadline and must be managed with minimal interruption to business-as-usual. ',
                    ],
                    color:'#000',
                },
                {
                    img:require('@/assets/images/Light.png'),
                    title:'APPROACH',
                    list:[
                        'A project organization was set up with key users from each department for accountability. ',
                        'The project put emphasis on defining and streamlining key business processes at group level in effort to reduce complexity of the data migration. ',
                        'The project team played a crucial role in focusing on the big picture at group level and communicating changes to different entities and different departments. ',
                    ],
                    color:'#000',
                },
                {
                    img:require('@/assets/images/Target.png'),
                    title:'RESULT',
                    list:[
                        'The new ERP was successfully implemented within project timeline of 12 months. The resulting state-of-the-art ERP provided information and transparency of the complete acquisition-to-cash process in real time, thus delivering all relevant KPIs at entity level and group level. ',
                    ],
                    color:this.isMobile?'#000':'#f1f1f1',
                }
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all {
    background: #ffffff;
    color: #000;
}



.case {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}


.case_red {
    background:#e72e2c;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 360px;
    right: 0;
}

.case_bg {
}
.case_bg1 {
    background: url("~@/assets/images/biglogo4.png");
    background-size: cover;
    width: 490px;
    height: 480px;
    position: absolute;
    top: -30px;
    left: -340px;
}




.case_logo {
    padding-top: 100px;
    padding-left: 160px;
}

.case_return {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
}

.case_title {
    font-weight: bold;
    font-size: 52px;
    padding-left: 160px;
    position: relative;

}

.case_intro {
    margin-top: 80px;
    width: 820px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 20px;
    line-height: 42px;
    position: relative;

}

@media screen and (max-width:768px){

    .case {
        width: 100%;
        overflow: hidden;

    }
    .case_title {
        position: relative;
        font-size: 40px;
        padding-left:50px
    }
    .case_intro {
        width: 100%;
        position: relative;
        font-size: 18px;
        box-sizing: border-box;
    }
    .case_red {
        display: none;
    }

}


</style>
