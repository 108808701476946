<template>
    <div>
        <caseA v-if="id==='1'"></caseA>
        <caseB v-if="id==='2'"></caseB>
        <caseC v-if="id==='3'"></caseC>
        <caseD v-if="id==='4'"></caseD>
    </div>
</template>

<script>

import caseA from "./case/caseA"
import caseB from "./case/caseB"
import caseC from "./case/caseC"
import caseD from "./case/caseD"


export default {
    components: {
        caseA,caseB,
        caseC,caseD,
    },

    mounted() {
        this.id = this.$route.params.id
        window.scrollTo({ "top": 0});
    },
    data() {
        return {
            id: 0,
            list: []
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
