<template>
    <div class="header">
        <c_content>

<!--            <div class="header_language">-->
<!--                <span @click="changeLanguage('en')" :class="{header_language_active:LANGUAGE_KEY==='en'}">EN</span>-->
<!--                <span class="header_language_splic">/</span>-->
<!--                <span @click="changeLanguage('de')" :class="{header_language_active:LANGUAGE_KEY==='de'}">DE</span>-->
<!--            </div>-->

            <div class="header_logo">
                <img src="@/assets/images/banner_logo.png" />
            </div>


<!--            <a class="header_download" href="./file/One-Reason-Consulting-Deck.pdf" download v-if="! IS_MOBILE">-->
<!--                <div v-show="LANGUAGE_KEY==='en'">Company Profile</div>-->
<!--                <div v-show="LANGUAGE_KEY==='de'">Firmenprofil</div>-->
<!--            </a>-->

            <div class="header_nav_button" v-if="isMobile" @click="showNav = ! showNav">
            </div>

            <div class="header_nav" v-if=" ! isMobile || showNav">
                <div v-for="(v,i) in navList" :key="i">
                    <a
                        @click="clickNav(i)"
                        :class="{active:v.active}"
                    >{{v.name[LANGUAGE_KEY]}}</a>
                </div>
            </div>


        </c_content>
    </div>
</template>

<script>
import bus from '@/js/bus/bus'
import language from "@/js/minxin/language";
import {useScreen} from "@/js/use/screen";

export default{
    mixins:[language],
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    data(){
        return {
            showNav:false,
            navList:[
                {name:{'en':'Our Vision','de':'Our Vision'},active:true,},
                {name:{'en':'What We Deliver','de':"Was wir liefern"},active:false,},
                {name:{'en':'Case Study','de':'XXX'},active:false,},
                {name:{'en':'Why Us','de':'Warum wir'},active:false,},
                {name:{'en':'About us','de':'Unser Team'},active:false,},
                // {name:{'en':'Contact Us','de':'Kontakt'},active:false,scroll:3600,scrollM:4500},
            ]

        }
    },
    mounted(){
        bus.on('enterPart',(i)=>{
            // console.log('头响应了,进入了'+i);
            for(let j in this.navList){
                let k  = j*1 + 1
                if( i === k){
                    this.navList[j].active = true
                }else{
                    this.navList[j].active = false
                }
            }
        })
    },
    methods:{
        changeLanguage(language){
            bus.emit('changeLanguage',language)
        },
        clickNav(i){

            // window.scrollTo({"behavior": "smooth", "top": this.isMobile?v.scrollM:v.scroll});

            // for(let item of this.navList){
            //     item.active = false
            // }

            // v.active = true

            bus.emit('clickNav',i+1)

            this.showNav = false
        }
    },
    components: {
    }
}
</script>

<style>


.header_logo img{
    width: 260px;
    margin-top: 14px;
}


.header {
    background: #252525;
    height: 90px;
    z-index: 10;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    box-sizing: border-box;

}






.header_language {float: right;cursor: pointer;color: #f1f1f1;}
.header_language_splic {color: #fb0001;font-weight: bold;cursor: default}
.header_language_active {font-weight: bold;}


.header_download {
    float: right;
    background: #e52e2d;
    box-shadow:1px 1px 1px 1px black;
    border: 2px solid #ff7b7a;
    border-bottom: none;
    border-right: none;
    text-decoration: none;
}
.header_download div {color: #f1f1f1;padding:5px 60px 5px 15px;background: url("~@/assets/images/download.png") no-repeat  95% center;cursor: pointer}


@media screen and (min-width:768px){
    .header .content {padding: 0 20px;box-sizing: border-box;}

    .header_nav {display: flex;position: absolute;right: 10px;top: 50px;}
    .header_nav a{color: #f1f1f1;text-decoration: none;font-weight: bold;font-size: 20px;}
    .header_nav a.active {color: #fb0001;}
    .header_nav div {padding: 0 30px 0 5px;cursor: pointer}

}


@media screen and (max-width:768px){


    .header {padding: 6px 10px;height: 75px;}
    .header_nav {justify-content: space-between;padding-top: 2px;}
    .header_nav div {padding: 0 13px 0 5px;cursor: pointer}

    .header_nav_button {
        width: 30px;
        height: 30px;
        background: url("~@/assets/images/button.png");
        background-size: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
    }


    .header_nav {position: absolute;top: 55px;right: 15px;background: #2c2b29;border-radius: 4px;box-shadow: 2px 1px 4px 0 #000;padding: 6px 3px;}
    .header_nav a{color: #f1f1f1;text-decoration: none;font-weight: bold;font-size: 18px;}
    .header_nav a.active {color: #fb0001;}
    .header_nav div {padding: 6px 8px;cursor: pointer}
    .header_logo img{
        width: 210px;
    }


}



</style>