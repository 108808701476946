<template>
    <div class="p1" ref="p1">



        <figure>
            <video class="p1_video" muted="" playsinline autoplay="" loop="" >
                <source  type="video/mp4" src="~@/assets/banner.mp4">
            </video>
        </figure>

        <c_content>
            <div class="p1_titles">
                <div class="p1_title1">{{LANGUAGE_TEXT[LANGUAGE_KEY]['title1']}}</div>
                <div class="p1_title2">{{LANGUAGE_TEXT[LANGUAGE_KEY]['title2']}}</div>
            </div>


<!--            <a class="header_download" href="./file/One-Reason-Consulting-Deck.pdf" download v-if="IS_MOBILE">-->
<!--                <div v-show="LANGUAGE_KEY==='en'">Company Profile</div>-->
<!--                <div v-show="LANGUAGE_KEY==='de'">Firmenprofil</div>-->
<!--            </a>-->


        </c_content>


    </div>
</template>

<script>
import language from "@/js/minxin/language";
import {ref} from "vue";
import {useScreen} from "@/js/use/screen";
import {usePart} from "@/js/use/part";

export default{
    mixins:[language],
    setup(){
        let dom  = ref(null)
        const {isMobile} = useScreen()
        usePart(1,dom)
        return {isMobile,dom}
    },
    mounted(){
        this.dom = this.$refs.p1
    },
    data() {
        return {
            LANGUAGE_TEXT: {
                'en': {
                    title1: 'We transform  your finance team to reach their full potentials as the true engine room for expansion. ',
                    title2: 'We understand, because we have been in your shoes.',
                },
                'de': {
                    title1: 'Wir transformieren Finanzteams, um ihr volles Potenzial als den wahren Maschinenraum für die Expansion in jedem schnell wachsenden Unternehmen auszuschöpfen. ',
                    title2: 'Wir verstehen, weil wir in Ihren Schuhen waren.',
                },
            }
        }
    },
    components: {
    }
}
</script>

<style>




@media screen and (min-width:768px){
    .p1 {
        color: #f1f1f1;padding-top: 110px;height: 690px;position: relative;top: 90px;overflow: hidden;background: #0f0f13;
    }

    .p1_video {
        object-fit: fill;
        transform: translate(-50%,-50%);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        min-width: 1450px;

    }
    .p1_titles {position: absolute;padding: 30px 0 0 20px;z-index: 1;}

    .p1_title1 {padding: 30px 0 20px 0;font-size: 36px;width: 505px;letter-spacing: 2px;line-height: 42px;}
    .p1_title2 {padding: 20px 0 30px 0;font-size: 24px;width: 500px;font-weight:bold;letter-spacing: 1px;line-height: 34px;}

}





@media screen and (max-width:768px){
    .p1 {
        color: #f1f1f1;padding-top: 65px;position: relative;background: #141318;padding-bottom: 170px;
    }
    .p1_video {
        width: 100%;
        object-fit: fill;
    }

    .p1_titles {padding: 0 10px;}
    .p1_title1 {padding: 30px 0 10px 0;font-size: 22px;letter-spacing: 1px;line-height: 28px;}
    .p1_title2 {padding: 30px 0 20px 0;font-size: 18px;font-weight:bold;letter-spacing: 1px;line-height: 22px;}
    .p1 .header_download {margin-top: 60px}

}




</style>