<template>
    <div class="all">

        <div class="case">
            <div class="case_bg">
                <div class="case_bg1"></div>
            </div>
            <div class="case_logo">
                <img src="@/assets/images/Case_study_title.png" width="200"/>
            </div>
<!--            <div class="case_return"  v-if="isMobile"  @click="back()">-->
<!--                <img src="@/assets/images/Close_X.png" width="30"/>-->
<!--            </div>-->
            <div class="case_title">OUTSOURCING</div>
            <div class="case_intro">The finance functions of the DACH (Germany, Austria and Switzerland) region were outsourced to an external service provider in East Europe. This project was part of the larger restructuring plan initiated by the Canadian Headquarter to streamline processes in Europe. The DACH region represented 180 mil Euro revenue and 450 FTE.</div>

            <caseList :list="list"></caseList>

        </div>
    </div>

</template>

<script>
import caseList from "../common/case/caseList"
import {ref} from "vue";
import {useScreen} from "@/js/use/screen";
import {usePart} from "@/js/use/part";


export default {
    components: {
        caseList
    },
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    mounted() {
    },
    methods:{
        back(){
            this.$router.back()
        },
    },
    data() {
        return {
            list:[
                {
                    img:require('@/assets/images/Hill.png'),
                    title:'CHALLENGE',
                    list:[
                        'The existing processes were outdated and had been performed by experienced accountants.',
                        'The service provider\'s team had no previous accounting background.',
                        'New processes and protocols needed to be established at service provider to account for high turnover.',
                    ],
                    color:'#f1f1f1',
                },
                {
                    img:require('@/assets/images/Light.png'),
                    title:'APPROACH',
                    list:[
                        'The knowledge transfer was done by in-person interview, all documentations were reviewed by process owner and finance manager. Process optimization was identified and implemented.',
                        'New process owners were identified early on to create accountability in training, implementation and establishing on-going quality control process.',
                        'Daily/weekly jour fixes were established to address issues at a timely manner.',
                    ],
                    color:'#f1f1f1',
                },
                {
                    img:require('@/assets/images/Target.png'),
                    title:'RESULT',
                    list:[
                        'DACH region outsourcing was completed as the fastest and most efficient project.',
                        'The headquarter and the service provider used our success as the model for the rest of the world.',
                        'The outsourcing project streamlined the accounting process in DACH with centralized reporting, reduced local head count by 10 FTE and generated synergy in Europe region and across the board.',
                    ],
                    color:'#f1f1f1',
                }
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all {
    background: #898989;
    color: #f1f1f1;
}



.case {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}


.case_bg {
}
.case_bg1 {
    background: url("~@/assets/images/biglogo.png");
    background-size: cover;
    width: 510px;
    height: 500px;
    position: absolute;
    top: -80px;
    right: -260px;
}




.case_logo {
    padding-top: 100px;
    padding-left: 30px;
}

.case_return {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
}

.case_title {
    font-weight: bold;
    font-size: 52px;
    padding-left: 30px;

}

.case_intro {
    margin-top: 60px;
    width: 820px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 20px;
    line-height: 42px;
}


@media screen and (max-width:768px){

    .case {
        width: 100%;
        overflow: hidden;

    }
    .case_title {
        position: relative;
        font-size: 40px;
    }
    .case_intro {
        width: 100%;
        padding-right: 20px;
        position: relative;
        font-size: 18px;
        box-sizing: border-box;
    }

    .case_bg1{
        right: -392px;
    }
}



</style>
