<template>
    <div>
        <ph></ph>
        <p1></p1>
        <p2></p2>
        <p5></p5>

        <p3></p3>
        <p4></p4>
        <pf></pf>
        <rightHang></rightHang>
    </div>
</template>

<script>
import ph from "./pageHeader"
import p1 from "./home/partA"
import p2 from "./home/partB"
import p3 from "./home/partC"
import p4 from "./home/partD"
import p5 from "./home/partF"
// import p5 from "./home/partE"
import pf from "./pageFooter"
import rightHang from "./home/rightHang"


export default {
    components: {
        p1, p2, p3, p4, p5,
        ph, pf,rightHang
    },
    props: {
        msg: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
