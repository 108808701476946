<template>
    <div class="content">
        <slot></slot>
    </div>
</template>

<script>

module.exports = {
    components: {
    }
}
</script>

<style>

@media screen and (min-width:768px){
    .content {width: 1200px;margin: 0 auto;position: relative;}
}

@media screen and (max-width:768px){
    .content {padding: 0 15px;}
}


</style>