import {ref,watch} from 'vue'
import bus from "@/js/bus/bus";
import {useScreen} from "@/js/use/screen";

export const usePart = (navIndex,partDom)=>{

    let isEntry = ref(false)
    bus.on('enterPart',(i)=>{
        // console.log(navIndex + '响应了,进入了'+i);
        if(i !== navIndex){
            isEntry.value = false
        }
    })


    const {scrollTop,isMobile} = useScreen()



    bus.on('clickNav',(i)=>{
        if(i === navIndex){
            window.scrollTo({"behavior": "smooth", "top": partDom.value.offsetTop - (isMobile?74:78) });
        }
    })


    watch(scrollTop,()=>{
        if(
            ! isEntry.value
            // && partDom.value
            &&  scrollTop.value > (partDom.value.offsetTop - 200 )
            &&  scrollTop.value < partDom.value.offsetTop + partDom.value.clientHeight - 200
        ){
            isEntry.value  = true
            bus.emit('enterPart',navIndex)
        }
    })

    return {isEntry}
}