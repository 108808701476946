<template>
    <div class="hang" @mouseenter="isOpen = true" @mouseleave="isOpen = false" @click="isOpen=!isOpen">

        <div class="hang_circle_up"></div>
        <div class="hang_handle"></div>
        <div class="hang_logo" ></div>
        <div class="hang_bar" :class="{'hang_bar_active':isOpen}">
                <a :href="'tel:'+phone"   @mouseenter="showPhone = true"  @mouseleave="showPhone = false" v-if="isOpen"><img src="@/assets/images/hang3.png"  /></a>
                <a :href="'mailto:'+email"  @mouseenter="showEmail = true"  @mouseleave="showEmail = false"  v-if="isOpen"><img src="@/assets/images/hang4.png" /></a>
                <a href="./file/One-Reason-Consulting-Deck.pdf" @mouseenter="showDown = true"  @mouseleave="showDown = false"   download @click.stop="stopPropagation"  v-if="isOpen"><img src="@/assets/images/hang5.png"/></a>
        </div>
        <div class="hang_circle_down"  :class="{'hang_circle_down_active':isOpen}"></div>
        <div v-if="!isMobile">
            <div class="hang_pop hang_phone" v-if="showPhone">{{phone}}</div>
            <div class="hang_pop hang_email" v-if="showEmail">{{email}}</div>
            <div class="hang_pop hang_down" v-if="showDown">Pitch Deck</div>
        </div>

<!--        <transition name="fade">-->
<!--            <div class="hang_text" v-if="isOpen"></div>-->
<!--        </transition>-->


    </div>
</template>

<script>

import {useScreen} from "@/js/use/screen";

export default{
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    mounted(){
    },
    methods:{
        clickTel(e){
        }
    },
    data() {
        return {
            isOpen:false,
            email: 'Info@OneReasonConsulting.com',
            phone: '+49 170 4554 578',
            showPhone:false,
            showEmail:false,
            showDown:false
        }
    },
    components: {
    }
}
</script>

<style scoped>
.hang {
    position: fixed;
    right: 10px;
    bottom: 250px;
    cursor: pointer;
}



@media screen and (max-width:768px){

    .hang {
        bottom: 250px;
        transform-origin: right;
    }


}

.hang_bar {
    user-select: none;
    top: 40px;
    z-index: 1;
    transform-origin: top;
    position: absolute;
    width: 92px;
    transition: all .2s;
    box-sizing: border-box;
    height: 0;
    background: url("~@/assets/images/hang_bg1.png") repeat-y;
}

.hang_bar_active {
    padding-top: 40px;
    height: 195px;
}


.hang_circle_up {
    width: 92px;
    height: 40px;
    background: url("~@/assets/images/hang_bg3.png");
}

.hang_circle_down {
    top: 40px;
    width: 92px;
    position: absolute;
    transition: all .2s;
    height: 53px;
    background: url("~@/assets/images/hang_bg2.png");

}

.hang_circle_down_active {
    top:235px;
}


.hang_bar img {
    display: block;
    margin: 10px auto;
    cursor: pointer;
    width: 50px;
    height: 50px;
}



.hang_handle {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 66px;
    z-index: 2;
    width: 17px;
    height: 11px;
    background: url("~@/assets/images/hang2.png");
    background-size: cover;

}

.hang_text {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 275px;
    z-index: 2;
    width: 26px;
    height: 8px;
    background: url("~@/assets/images/hang6.png");
    background-size: cover;

}


.hang_logo {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 100;
    top: -13px;
    width:74px;
    height: 75px;
    background: url("~@/assets/images/hang1.png");
    background-size: cover;
}


.fade-enter-active {
    transition: all .5s;
}
.fade-enter-from, .scale-leave-to  {
    opacity: 0
}

.hang_pop {
    font-family: Arial, sans-serif;
    font-size: 20px;
    background: linear-gradient(45deg, #e92a2a, transparent);
    font-weight: bold;
    padding: 12px 7px;
    color: #f1f1f1;
    border-radius: 5px;
    position: absolute;
    right: 90px;
    white-space: nowrap;
    /*text-shadow: 1px 3px 2px black;;*/
    z-index: 1;
}
.hang_phone{
    top: 92px;
}
.hang_email{
    top: 150px;
}
.hang_down{
    top: 214px;
}

</style>