<template>
    <div>
<!--        <nav>-->
<!--            <router-link to="/">Home</router-link> |-->
<!--            <router-link to="/about">About</router-link>-->
<!--        </nav>-->
        <router-view/>
    </div>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
