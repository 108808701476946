<template>
    <div class="all">

        <div class="case">
            <div class="case_bg">
                <div class="case_bg1"></div>
            </div>
            <div class="case_logo">
                <img src="@/assets/images/Case_study_title.png" width="200"/>
            </div>
<!--            <div class="case_return"  v-if="isMobile"   @click="back()">-->
<!--                <img src="@/assets/images/Close_X.png" width="30"/>-->
<!--            </div>-->
            <div class="case_title">
                FINANCIAL<br/>
                REPORTING<br/>
                RE-ENGINEERED
            </div>
            <div class="case_intro">The rapid growing startup was transitioning into a mature company, but it did not have the numbers to support its strategic decision making. The stakeholders complained the financial numbers were not understandable and therefore deemed all financial information irrelevant. The original objective was to optimize the financial planning and analysis (FP&A) structure and process to provide the transparency the company so desperately needed. </div>

            <caseList :list="list"></caseList>

        </div>
    </div>

</template>

<script>
import caseList from "../common/case/caseList"
import {useScreen} from "@/js/use/screen";


export default {
    components: {
        caseList
    },
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    mounted() {
    },
    methods:{
        back(){
            this.$router.back()
        },
    },
    data() {
        return {
            list:[
                {
                    img:require('@/assets/images/Hill.png'),
                    title:'CHALLENGE',
                    list:[
                        'There was an overall disconnection between Board of Director, Operation, Accounting and Finance due to unstructured and incomprehensible planning and reporting structure.',
                    ],
                    color:'#f1f1f1',
                },
                {
                    img:require('@/assets/images/Light.png'),
                    title:'APPROACH',
                    list:[
                        'Conducted face-to-face interviews with all relevant stakeholders to understand the root cause.',
                        'Prioritized key issues from each group of stakeholders. ',
                        'Developed and presented solutions in iterations to maximize collaboration and value delivery. ',
                        'Tailored roll-out and training plans for each group. ',
                    ],
                    color:'#f1f1f1',
                },
                {
                    img:require('@/assets/images/Target.png'),
                    title:'RESULT',
                    list:[
                        'The outdated chart of accounts and financial reporting structures were identified as root cause and re-engineered completely to provide clarity and transparency. ',
                        'New financial planning structure and process was rolled out to all stakeholders with one-on-one trainings. ',
                        'The Board of Directors commented "we finally understand the numbers for the very first time!"',
                    ],
                    color:'#f1f1f1',
                }
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all {
    background: #000000;
    color: #f1f1f1;
}



.case {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}


.case_bg {
}
.case_bg1 {
    background: url("~@/assets/images/biglogo.png");
    background-size: cover;
    width: 510px;
    height: 500px;
    position: absolute;
    top: 80px;
    left: -260px;
}




.case_logo {
    padding-top: 70px;
    padding-left: 180px;
}

.case_return {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
}

.case_title {
    position: relative;
    font-weight: bold;
    font-size: 52px;
    padding-left: 180px;

}

.case_intro {
    margin-top: 60px;
    width: 820px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 300px;
    line-height: 42px;
}

@media screen and (max-width:768px){

    .case {
        width: 100%;
        overflow: hidden;

    }
    .case_title {
        position: relative;
        font-size: 40px;
        padding-left: 120px;
    }
    .case_intro {
        width: 100%;
        padding-left:20px;
        padding-right: 20px;
        position: relative;
        font-size: 18px;
        box-sizing: border-box;
    }



}

</style>
