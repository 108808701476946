<template>
    <div class="p3" id="p3" ref="p3" :style="{paddingTop:adjust.top,paddingBottom:adjust.bottom}">
        <c_content>
            <div class="p3_bg">

                <h1 class="p3_title" v-if="isMobile"><img style="padding-top: 20px;" src="@/assets/images/Why_Us.png" /></h1>

                <div class="p3_content">
                    <transition :name="'fly'+i"  v-for="(v,i) in list" :key="i">
                        <div class="p3_item" v-if="isShow">
                            <img :src="imgSrc[i]" />
                            <p>
                                {{ v.title }}
                            </p>
                        </div>
                    </transition>
                </div>

                <h1 class="p3_title" v-if="!isMobile"><img style="padding-top: 20px;" src="@/assets/images/Why_Us.png" /></h1>

            </div>
        </c_content>
    </div>
</template>

<script>
import bus from '@/js/bus/bus'
import {ref} from 'vue'

import {useScreen} from '@/js/use/screen'
import {usePart} from '@/js/use/part'
import language from "@/js/minxin/language";

export default{
    mixins:[language],
    setup(){
        let dom  = ref(null)
        const {isMobile,scrollTop,screenHeight} = useScreen()

        const {isEntry} = usePart(4,dom)

        return {isMobile,scrollTop,screenHeight,isEntry,dom}
    },
    mounted(){
        this.dom = this.$refs.p3

        let offset = this.screenHeight  - this.dom.offsetHeight - 66
        this.adjust.top = offset/2+'px';
        this.adjust.bottom = offset/2+'px';
    },
    watch: {
        scrollTop(val, oldVal) {
            let top = this.$refs.p3.offsetTop - this.screenHeight + 300;
            let bottom = this.$refs.p3.offsetTop + this.screenHeight - 400


            if(    !this.isShow  &&  val > top  &&  val < bottom ){
                this.isShow = true

            }else if(
                this.isShow  &&
                (
                    val < top  ||  val > bottom
                )
            ){
                this.isShow = false
            }
        },
    },
    methods:{

    },
    data() {
        return {
            adjust:{
                top:0,
                bottom:0
            },
            imgSrc: [require('@/assets/images/icon_1.png'),require('@/assets/images/icon_2.png'),require('@/assets/images/icon_3.png')],
            isShow:false,
            isIn:false,
            list: [
                {
                    title: 'We truly understand your challenges and pain points. Our expertise comes from personal experience in running and transforming finance offices.',
                },
                {
                    title: 'We use a holistic approach that connects operations, accounting and finance.',
                },
                {
                    title: 'We use Agile methodology to ensure that we deliver solutions that are tailored to the specific needs of your company. ',
                },

            ]
        }
    },
    components: {}
}
</script>

<style>

.fly0-enter-active {
    transition: all 1s;
}
.fly1-enter-active {
    transition: all 1s;
    transition-delay: .5s;
}
.fly2-enter-active {
    transition: all 1s;
    transition-delay: 1s;
}
.fly0-enter-from,.fly1-enter-from,.fly2-enter-from {
    transform: translateX(600px);
    opacity: 0;
}




.p3 {
    overflow: hidden;
    background: #6c6c6c;
    color: #252525;
    padding-bottom: 40px;
}

.p3_bg {
    width: 830px;
    background: url("~@/assets/images/Map.png") no-repeat center;
}

.p3_title {
    width: 700px;
    font-weight: bold;
    color: #f1f1f1;
    background: url("~@/assets/images/Gray logo.png") no-repeat center;
    text-align: center;
    font-size: 50px;
    padding: 350px 0 350px 0;
}

.p3_content {
    color: #f1f1f1;
    font-weight: bold;
    position: relative;
}

.p3_item {
    text-align: center;
    position: absolute;
    font-size: 18px;
    line-height: 30px;
    width: 450px;
    display: flex;
    align-items: center;
}

.p3_item img{padding-right: 20px;}





@media screen and (min-width:768px){
    .p3_item:nth-child(1) {
        top: 85px;
        left: 665px;
    }

    .p3_item:nth-child(2) {
        top: 309px;
        left: 755px;
    }
    .p3_item:nth-child(3) {
        top:524px;
        left: 665px;

    }

}

@media screen and (max-width:768px){
    .p3 {
        padding: 20px 0 60px 0;
    }
    .p3_bg {
        width: 100%;
        background: url("~@/assets/images/MapM.png") no-repeat center 30px;
        background-size: 340px;
    }
    .p3_title {
        width: 100%;
        background: url("~@/assets/images/Gray logo.png") no-repeat center;
        background-size: 220px;
        font-size: 38px;
        padding: 100px 0;
    }

    .p3_title img{
        width: 180px;
    }

    .p3_content {
        padding-top: 10px;
        min-height: 409px;
    }

    .p3_item {
        text-align: left;
        position: static;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        padding: 40px 0 5px 0;

    }

    .p3_item img{padding-right: 20px;width: 60px;}


}



</style>