<template>
    <div class="p4" id="p4" ref="p4">
        <c_content>
<!--            <div class="p4_title">-->
<!--                About us-->
<!--                <div class="p4_title_border"></div>-->
<!--            </div>-->
            <div class="">
                <div class="p4_item" v-for="(v,i) in LANGUAGE_TEXT[LANGUAGE_KEY]['people']"  :key="i">

                    <div class="p4_contenct">
                        <img src="@/assets/images/Diamond.png" />
                    </div>


                    <div class="p4_pic">
                        <img :src="imgSrc[i]" />
                    </div>


                    <div class="p4_info">
                        <div class="p4_name">About <b>{{v.title}}</b></div>
                        <div class="p4_text" v-html="v.info">
                        </div>
                    </div>


                </div>
            </div>

        </c_content>

    </div>
</template>

<script>

import language from "@/js/minxin/language";
import {useScreen} from "@/js/use/screen";
import {ref} from "vue";
import {usePart} from "@/js/use/part";

export default{
    mixins:[language],
    setup(){
        let dom  = ref(null)
        const {isMobile,scrollTop} = useScreen()
        usePart(5,dom)
        return {isMobile,dom,scrollTop}
    },
    mounted(){
        this.dom = this.$refs.p4
    },
    watch: {
        scrollTop(val, oldVal) {

            if(! this.isTyping[0] ){
                if( window.scrollY > this.$refs.p4.offsetTop - 200 ){
                    this.isTyping[0] = true
                    this.typing(0)
                }
            }else{
                if( window.scrollY < this.$refs.p4.offsetTop - 500 ){
                    this.isTyping[0] = false
                }
            }


            if(! this.isTyping[1] ){
                if( window.scrollY > this.$refs.p4.offsetTop + 500 ){
                    this.isTyping[1] = true
                    this.typing(1)
                }
            }else{
                if( window.scrollY < this.$refs.p4.offsetTop + 200 ){
                    this.isTyping[1] = false
                }
            }
        },
    },
    methods:{
        typing(key){
            let textAll = this.LANGUAGE_TEXT[this.LANGUAGE_KEY]['people'][key]['info']

            let textNow = textAll.slice(0,- 150)
            let textPool = textAll.slice(- 150)

            this.LANGUAGE_TEXT[this.LANGUAGE_KEY]['people'][key]['info'] = textNow

            let  cursor = ' <b style="color: #fcd86c;">I</b>'

            let timer = setInterval(()=>{


                textNow += textPool.slice(0,1)
                textPool = textPool.slice(1)
                if( ! textPool.length){
                    clearInterval(timer)
                    cursor = ''
                }

                this.LANGUAGE_TEXT[this.LANGUAGE_KEY]['people'][key]['info'] = textNow + cursor

                if( ! this.isTyping[key] ){
                    this.LANGUAGE_TEXT[this.LANGUAGE_KEY]['people'][key]['info'] = textAll
                    clearInterval(timer)
                }


            },50)
        }
    },
    data() {
        return {
            imgSrc: [require('@/assets/images/people0.png'),require('@/assets/images/people1.png')],
            isTyping:[false,false],
            LANGUAGE_TEXT:{
                'en':{
                    people:[
                        {
                            title: 'Jens',
                            info: 'As a seasoned CFO, Jens innovates outside-the-box strategies, develops a coherent plan and motivates cross-functional teams to accomplish the mission.\n\rWhat differentiates him is his hands-on mentality combined with a strategic and business focused mindset. He has been the "go to" executive for spearheading large scale restructuring and expansion efforts, maintaining financial stability and driving significant growth.\n\rJens has a long history in the unified communications industry in Europe. Jens has always been front and centre of the company\'s transformation as it evolved from a million-euro German company to a billion-dollar international telecommunication giant. He has more than 25 years of experience in finance leadership and a tremendous amount of experience in business transformation through rapid growth, M&A, restructuring and digital transformation. His wealth of experience makes him an asset to any business.',
                            bio: '',
                        },
                        {
                            title: 'Sally',
                            info: 'Sally developed a passion for helping small and mid-sized companies with their finance functions when she led a team of internal auditors in Europe and North America to transform business operations and financial performances.\n\rShe was recognized in her past leadership roles by her Senior Management and Board of directors for her focus on re-engineering and revolutionizing key business and finance processes, her ability in utilizing agile approach in finance, and her drive to make things happen.\n\rSally has a Master\'s degree in Accounting from University of Waterloo in Canada with specialization in Finance. She is a KPMG alumni and she still holds a CPA, CA in Canada.',
                            bio: '',
                        }
                    ]
                },
                'de':{
                    people:[
                        {
                            title: 'Jens',
                            info: 'Als erfahrener CFO entwickelt Jens Strategien über den Tellerrand hinaus, entwickelt einen kohärenten Plan und motiviert funktionsübergreifende Teams, die Mission zu erfüllen. Was ihn auszeichnet, ist seine Hands-on-Mentalität in Kombination mit einer strategischen und geschäftsorientierten Denkweise. Er war der "Go to" -Manager für die Speerspitze groß angelegter Umstrukturierungs- und Expansionsbemühungen, die Aufrechterhaltung der finanziellen Stabilität und die Förderung eines signifikanten Wachstums. \n\rJens blickt auf eine lange Geschichte in der Unified Communications-Branche in Europa zurück. Jens stand schon immer im Mittelpunkt der Transformation des Unternehmens, als es sich von einem deutschen Millionen-Euro-Unternehmen zu einem internationalen Milliarden-Dollar-Telekommunikationsriesen entwickelte. Er verfügt über mehr als 25 Jahre Erfahrung in der Finanzführung und eine enorme Erfahrung in der Geschäftstransformation durch schnelles Wachstum, M&A, Restrukturierung und digitale Transformation. Sein Erfahrungsschatz macht ihn zu einer Bereicherung für jedes Unternehmen.',
                            bio: '',
                        },
                        {
                            title: 'Sally',
                            info: 'Sally developed a passion for helping small and mid-sized companies with their finance functions when she led a team of internal auditors in Europe and North America to transform business operations and financial performances.\n\rShe was recognized in her past leadership roles by her Senior Management and Board of directors for her focus on re-engineering and revolutionizing key business and finance processes, her ability in utilizing agile approach in finance, and her drive to make things happen.\n\rSally has a Master\'s degree in Accounting from University of Waterloo in Canada with specialization in Finance. She is a KPMG alumni and she still holds a CPA, CA in Canada.',
                            bio: '',
                        }
                    ]
                }
            }

        }
    },
    components: {
    }
}
</script>

<style>

.p4 {
    background: #2c2b29;
    color: #fccb04;
    padding: 30px 0 40px 0;
    background: url("~@/assets/images/Why Us Background.png");

}


.p4_title {
    color: #fff;
    text-shadow: 3px 3px 3px #000000;
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    padding: 50px 0 80px 0;
}
.p4_title_border {
    margin: 5px auto;
    width: 240px;
    height: 2px;
    border: 1px solid #fff;
}


.p4_item {
    display: flex;
    position: relative;
}




@media screen and (min-width:768px){



    .p4_contenct {position: absolute;top: 200px;left: 600px;}

    .p4_contenct img{
        width: 150px;
        animation-name: upDown;
        animation-duration: 3s;
        animation-direction:alternate;
        animation-iteration-count: infinite;
    }

    .p4_item:first-child .p4_info {
        padding: 30px 28px 30px 100px;
    }
    .p4_item:first-child .p4_contenct {
        left: 400px;
    }
    .p4_item:nth-child(2) .p4_pic {
        order: 1;
    }


}



.p4_pic {
    text-align: center;
    width: 500px;
    padding:163px 0 200px 0;
    flex-shrink:0;
    background: url("~@/assets/images/OurTeam_Gray ring.png") no-repeat 0 118px;
    background-size: 500px 500px;

}




@keyframes upDown {
    0% {
        transform:translateY(-40px);
    }
    100% {
        transform:translateY(40px);
    }
}




.p4_info {
    padding: 30px 100px 30px 28px;
}
.p4_name {
    font-size: 32px;
    padding-bottom: 30px;
}

.p4_text {
    position: relative;
    white-space: pre-line;
    line-height: 26px;
    font-size: 22px;
}
.p4_text:before {
    position: absolute;
    left: -35px;
    top: -10px;
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    background: url("~@/assets/images/sign1.png");
}
.p4_text:after {
    position: absolute;
    right: 0;
    bottom: -10px;
    content: "";
    width: 30px;
    height: 30px;
    display: block;
    background: url("~@/assets/images/sign2.png");
}


@media screen and (max-width:768px){
    .p4_title {
        font-size: 38px;
    }
    .p4_item {
        display: block;
    }
    .p4_contenct {display: none;}


    .p4_pic {
        width: 100%;
        padding: 82px 0;
        background: url("~@/assets/images/OurTeam_Gray ring.png") center no-repeat;
        background-size: 355px;
    }
    .p4_pic img {width: 200px;}

    .p4_info {
        padding: 30px 20px 30px 28px;
    }

}


</style>