import bus from '@/js/bus/bus'

export default {
    data(){
        return {
            LANGUAGE_KEY: 'en',
        }
    },
    mounted(){
        bus.on('changeLanguage', language => {
            this.LANGUAGE_KEY = language
        })

    }
,
}