<template>
    <div class="p2" id="p2" ref="p2" :style="{paddingTop:adjust.top,paddingBottom:adjust.bottom}">
        <c_content>
            <h1 class="p2_title"  v-if="isMobile"><img src="~@/assets/images/What_We_Deliver.png" /></h1>
            <div class="p2_logo">
                <img class="" src="~@/assets/images/WhatWeDeliver_logo.png" />
            </div>
            <div class="p2_content">
                <div class="p2_item" v-for="(v,i) in list" :key="i">
                    <div class="p2_item_title">{{v.title}}</div>
                    <div class="p2_item_line"></div>
                    <div class="p2_item_list">
                        <div class="p2_item_list_item" v-for="(v2,i2) in v.content" :key="i2">
                            <div class="p2_item_list_icon"></div>
                            {{v2}}
                        </div>
                    </div>
                </div>
            </div>
        </c_content>

    </div>
</template>

<script>

import language from "@/js/minxin/language";
import {useScreen} from "@/js/use/screen";
import {ref} from "vue";
import {usePart} from "@/js/use/part";

export default{
    mixins:[language],
    setup(){
        let dom  = ref(null)
        const {isMobile,screenHeight} = useScreen()
        usePart(2,dom)
        return {isMobile,dom,screenHeight}
    },
    mounted(){
        this.dom = this.$refs.p2

        let offset = this.screenHeight  - this.dom.offsetHeight - 66

        console.log(this.screenHeight);
        console.log( this.dom.offsetHeight);
        this.adjust.top = offset/2+'px';
        this.adjust.bottom = offset/2+'px';
    },
    data() {
        return {
            adjust:{
                top:0,
                bottom:0
            },
            title: 'What We Deliver',
            list: [
                {
                    title: 'Interim Management',
                    content: [
                        "Interim CFO services",
                        "Ad hoc finance transformation management",
                    ]
                },
                {
                    title: 'Process Related Services',
                    content: [
                        "Key business process review, standardization and automation",
                        "Internal control design and implementation"
                    ]
                },
                {
                    title: 'Financial Transformation',
                    content: [
                        "Post M&A change management",
                        "Outsourcing/ insourcing management",
                        "Restructuring support",
                    ]
                },
                {
                    title: 'Financial Reporting',
                    content: [
                        "Financial reporting, planning and analysis structure re-design",
                        "New subsidiary setup",
                        "New reporting package setup",
                    ]
                },


            ]

        }
    },
    components: {
    }
}
</script>

<style>

@media screen and (min-width:768px){
    .p2 {margin-top: 90px;}

}



.p2 {background: #b9bab9;color: #252525;position: relative;}

.content {}


.p2_title {text-align: center;padding: 80px 0 40px 0;font-size: 50px;color: #ffcc00;}

.p2_logo {position: absolute;width: 100%;;margin: 160px auto 0 auto;text-align: center}

.p2_logo img {
    padding-left: 5px;

    animation-name: beat;
    animation-duration: 1s;
    animation-direction:alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}


@keyframes beat {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(0.9)
    }
}








.p2_item {
    font-size: 18px;
    width: 340px;
    padding: 50px 0 70px 50px;
}

.p2_item:first-child{
    margin-bottom: 60px;
    margin-right: 100px;
}


.p2_item_title {font-size: 26px;padding-bottom: 5px;font-weight: bold;}

.p2_item_line {width: 50px;height: 4px;background: #ffcc00;margin-bottom: 20px;}

.p2_item_list {line-height: 30px;color: #252525;}
.p2_item_list_item {
    position: relative;
}

.p2_item_list_icon {width: 12px;height: 10px;background: #ffcc00;transform: skew(-25deg, 0deg);position: absolute;left: -18px;top:10px;}



@media screen and (min-width:768px){
    .p2_content {
        display: flex;
        justify-content: space-between;
        padding: 30px 0 20px 0;
        background: url("~@/assets/images/WhatWeDeliver_image_1.png") no-repeat center;
        flex-wrap: wrap;
        background-size: 550px;
    }
    .p2_item:hover {
        transform: scale(1.1);
        transition: transform;
        transition-duration: 0.2s;
    }

    .p2_item{
        position: relative;
    }

    .p2_item:nth-child(1),.p2_item:nth-child(3){
        left: -80px;
    }
    .p2_item:nth-child(2),.p2_item:nth-child(4){
        right: -80px;
    }

}



@media screen and (max-width:768px){
    .p2 {padding-bottom: 60px;}
    .p2_title {font-size: 38px;padding:  50px 0 0 0;}
    .p2_title img {width:85%;}

    .p2_content {padding-bottom: 40px;}
    .p2_item {
        padding: 50px 20px 0 25px;
        font-size: 16px;
        width: 100%;
        box-sizing: border-box;
    }
    .p2_item:first-child{
        margin-bottom: 0;
        margin-right: 0;
    }


    .p2_logo {display: none}


    .p2_item_title {font-size: 20px;padding-bottom: 5px;}

    .p2_item_list {}
}



</style>