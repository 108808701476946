<template>
    <div class="all">

        <div class="case">
            <div class="case_bg">
                <div class="case_bg1"></div>
                <div class="case_bg2"></div>
            </div>
            <div class="case_logo">
                <img src="@/assets/images/Case_study_title.png" width="200"/>
            </div>
<!--            <div class="case_return" v-if="isMobile"   @click="back()">-->
<!--                <img src="@/assets/images/Close_X.png" width="30"/>-->
<!--            </div>-->
            <div class="case_title">
                POST MERGER<br />
                INTEGRATION(PMI)
            </div>
            <div class="case_intro">The telecommunication company rigorously pursued the buy-and-build strategy to become the industry leader in the German market. The company acquired two more entities (80 million euro in sales / 300 headcount) in 2018 and the success of the Post Merger Integration was key to realize the strategy of expanding market access and achieving cost synergies. </div>

            <caseList :list="list"></caseList>

        </div>
    </div>

</template>

<script>
import caseList from "../common/case/caseList"
import {useScreen} from "@/js/use/screen";


export default {
    components: {
        caseList
    },
    setup(){
        const {isMobile} = useScreen()
        return {isMobile}
    },
    mounted() {
    },
    methods:{
        back(){
            this.$router.back()
        },
    },
    data() {
        return {
            list:[
                {
                    img:require('@/assets/images/Hill.png'),
                    title:'CHALLENGE',
                    list:[
                        'All entities established unique corporate cultures with different processes and tools. ',
                        'All entities had long-tenured employees with different levels of qualification, the management anticipated reluctance to process and technology changes. ',
                        'There were limited management resources to manage the change in such a large scale. ',
                    ],
                    color:'#000000',
                },
                {
                    img:require('@/assets/images/Light.png'),
                    title:'APPROACH',
                    list:[
                        'Established PMI project structure with steering board, project management office, 9 workstreams and external consultants. ',
                        'Performed holistic analysis of offer-to-cash process in each company to gain understanding of each company’s unique business, to identify potential synergies and to evaluate key differences. ',
                        'Established and followed a clear project charter to manage and communicate integration changes with transparency for all stakeholders. ',
                    ],
                    color:'#000000',
                },
                {
                    img:require('@/assets/images/Target.png'),
                    title:'RESULT',
                    list:[
                        'The operating companies were successfully integrated under company law, the planned organizational structure and processes were implemented without significant interruption to business as usual. Post merger EBITDA increased from 5% to 9%.',
                        'The resource management was standardized leading to increased productivity as observed in the technical department, where the productivity level increased from 75% to 85%.',
                    ],
                    color:'#000000',
                }
            ]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.all {
    background: #5e5e5e;
    color: #000000;
}



.case {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}


.case_bg {
}
.case_bg1 {
    background: url("~@/assets/images/biglogo.png");
    background-size: cover;
    width: 410px;
    height: 400px;
    position: absolute;
    top: 530px;
    right: -35px;
}

.case_bg2 {
    background: url("~@/assets/images/biglogo3.png");
    background-size: cover;
    width: 610px;
    height: 600px;
    position: absolute;
    top: 700px;
    left: -400px;
}



.case_logo {
    padding-top: 100px;
    padding-left: 30px;
}

.case_return {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
}

.case_title {
    font-weight: bold;
    font-size: 52px;
    padding-left: 30px;

}

.case_intro {
    margin-top: 60px;
    width: 820px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: 20px;
    line-height: 42px;
}

@media screen and (max-width:768px){

    .case {
        width: 100%;
        overflow: hidden;

    }
    .case_title {
        position: relative;
        font-size: 40px;
    }
    .case_intro {
        width: 100%;
        position: relative;
        font-size: 18px;
        box-sizing: border-box;
    }

}


</style>
