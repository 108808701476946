<template>
    <div class="p5" id="p5" ref="p5"  :style="{paddingTop:adjust.top,paddingBottom:adjust.bottom}">

        <h1 class="p5_title"  v-if="isMobile"><img class="p5_bg_ball0" src="@/assets/images/Case_study_ball.png" /></h1>

        <div class="p5_bg">
            <img class="p5_bg_ball0" src="@/assets/images/Case_study_ball.png" />
            <img class="p5_bg_ball1" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball2" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball3" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball4" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball5" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball6" src="@/assets/images/White_ball.png" />
            <img class="p5_bg_ball7" src="@/assets/images/White_ball.png" />
        </div>

        <c_content>
            <div class="p5_content">
                <div class="p5_list">
                    <router-link
                        :to="'/case/'+(i+1)"
                        :class="['p5_item','p5_item'+i,{'p5_item_hover':item.isHover}]"
                        @mousemove="enter(item)"
                        @mouseleave="leave(item)"
                        v-for="item,i in list"
                        :key="item.path"
                        :target="isMobile?'':'_blank'"
                    >
                        <p class="p5_item_title" v-html="item.titele"></p>
                        <div class="p5_item_more" >> READ MORE...</div>
                    </router-link>

                </div>
            </div>
        </c_content>
    </div>
</template>

<script>

import language from "@/js/minxin/language";
import {ref} from "vue";
import {useScreen} from "@/js/use/screen";
import {usePart} from "@/js/use/part";

export default{
    mixins:[language],
    setup(){
        let dom  = ref(null)
        const {isMobile,screenHeight} = useScreen()
        usePart(3,dom)
        return {isMobile,dom,screenHeight}
    },
    mounted(){
        this.dom = this.$refs.p5

        let offset = this.screenHeight  - this.dom.offsetHeight  - 66
        this.adjust.top = offset/2+'px';
        this.adjust.bottom = offset/2+'px';
    },
    methods:{
        enter(curItem){
            this.leave()
            curItem.isHover = true
        },
        leave(){
            for(let item of this.list){
                item.isHover = false
            }
        },
    },
    data() {
        return {
            adjust:{
                top:0,
                bottom:0
            },
            title:'CASE STUDY',
            list:[
                {titele:'OUTSOURCING',path:'o',isHover:false},
                {titele:'FINANCIAL<br/>REPORTING<br/>RE-ENGINEERED',path:'f',isHover:false},
                {titele:'POST MERGER<br/>INTEGRATION',path:'p',isHover:false},
                {titele:'ERP<br/>IMPLEMENTATION',path:'e',isHover:false},
            ]
        }
    },
    components: {
    }
}
</script>

<style>
.p5 {background: #eef0ef;overflow: hidden;height: 780px;align-items: center;display: flex;position: relative;}
/*.p5 {background: #0bd56f;}*/

.p5_title {text-align: center;padding: 40px 0 0 0;}

.p5_bg {
    position: absolute;
    width: 1200px;
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
}
.p5_bg img {position: absolute;}
.p5_bg_ball0 {width: 300px;top: 20px;left: -180px;}
.p5_bg_ball1 {width: 100px;top: 580px;left: -10px;}
.p5_bg_ball2 {width: 90px;top: 80px;left: 280px;}
.p5_bg_ball3 {width: 50px;top: 650px;left: 320px;}
.p5_bg_ball4 {width: 80px;top: 650px;left: 870px;}
.p5_bg_ball5 {width: 170px;top: 20px;left: 1050px;}
.p5_bg_ball6 {width: 30px;top: 560px;left: 1090px;}
.p5_bg_ball7 {width: 120px;top: 420px;right: -130px;}

.p5_list {
    position: relative;
    height: 630px;
}

.p5_item_title {font-size: 20px;width: 100%;font-weight: bold;}
.p5_item_more {font-size: 12px;color: #f7c92e;width: 100%;padding-top: 25px;font-weight: bold;z-index: 1;text-decoration: underline;}




.p5_item0 {
    top:270px;
    left:10px;
    width: 300px;
    height: 300px;
}
.p5_item0::after {
    background: url("~@/assets/images/Circle-1.png") no-repeat;
}
.p5_item1 {
    top:1px;
    left:310px;
    width: 400px;
    height: 400px;
}
.p5_item1::after {
    background: url("~@/assets/images/Circle-2.png") no-repeat;
}
.p5_item2 {
    bottom:1px;
    left:590px;
    width: 300px;
    height: 300px;
}
.p5_item2::after {
    background: url("~@/assets/images/Circle-3.png") no-repeat;
}
.p5_item3 {
    top:140px;
    right:10px;
    width: 300px;
    height: 300px;
}
.p5_item3::after {
    background: url("~@/assets/images/Circle-4.png") no-repeat;
}

.p5_item {
    color: #111;
    text-decoration: none;
    position: absolute;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    cursor: pointer;
}
.p5_item::after {
    content:'';
    background-size: cover;
    width: 100%;
    position: absolute;
    height: 100%;
    transform: rotate(0deg);
    transition: transform;
    transition-duration: 1s;
}
.p5_item_hover::after {
    transform: rotate(360deg);
    transition: transform;
    transition-duration: 1s;
    /*transition-timing-function: ease-in-out;*/
}


@media screen and (max-width:768px){

    .p5 {
        display: block;
        height: 100%;
    }
    .p5_content {
        padding-bottom: 30px;
    }
    .p5_list {
        height: 100%;
    }
    .p5_list a{
        margin-top: 25px;
    }
    .p5_item {
        position: static;
        width: 100%;
        height: 300px;
    }

    .p5_item::after {
        content:'';
        background-size: contain;
        background-position: center;
        width: 100%;
        height: 300px;
    }

}



</style>