<template>
    <div class="list">
        <div class="list_item" v-for="item,i in list" :key="i">
            <div class="list_item_img">
                <img :src="item.img" width="80"/>
            </div>
            <div class="list_item_title">{{item.title}}</div>
            <div class="list_item_list" v-for="item2,i2 in item.list" :key="i2" :style="{color: item.color}">
                {{item2}}
            </div>
        </div>
    </div>
</template>

<script>


export default{
    mounted(){
    },
    props:{
        list:[]
    },
    data() {
        return {

        }
    },
    components: {
    }
}
</script>

<style scoped>
.list {
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
}
.list_item {
    width: 28%;
}

.list_item_img {
    text-align: center;
    margin-bottom: 30px;
}

.list_item_title {
    text-align: center;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #f5c62e;
}

.list_item_list {
    position: relative;
    margin-top: 25px;
    line-height: 26px;
    font-size: 20px;
}
.list_item_list::before{
    content: "";
    position: absolute;
    left: -15px;
    top: 6px;
    width: 10px;
    height: 10px;
    background: #f5c62e;
    transform: skew(0deg, -20deg);
}


@media screen and (max-width:768px){


    .list{
        display: block;
    }
    .list_item {
        width: 100%;
        position: relative;
    }
    .list_item_list {
        padding: 0 10px 20px 10px;
    }
}



</style>