import {ref} from 'vue'

export const useScreen = (handleScroll)=>{
    let isMobile = ref(false)
    let scrollTop = ref(0)
    let screenWidth = ref(0)
    let screenHeight = ref(0)

    let computedWidth = () => {
        screenWidth.value = window.innerWidth
        screenHeight.value = window.innerHeight

        if (screenWidth.value < 768) {
            isMobile.value = true
        } else {
            isMobile.value = false
        }
    }
    window.onresize = () => {
        computedWidth()
    }
    computedWidth()

    window.addEventListener("scroll", ()=>{
        scrollTop.value = window.scrollY
    }, true);


    return {isMobile,scrollTop,screenWidth,screenHeight}
}