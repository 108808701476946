<template>
    <div class="footer">
        <div class="footer_item" v-for="(v,i) in list" :key="i" @click="onClick(v.path)">{{v.title}}</div>
    </div>
</template>

<script>

import language from "@/js/minxin/language";

export default{
    mixins:[language],

    data() {
        return {
            list:[
                {title:'Corporate Information',path:'corporate.html'},
                {title:'Privacy Notice',path:'privacy.html'},
                {title:'Cookie Policy',path:'cookie.html'},
            ]
        }
    },
    methods:{
        onClick(path){
            window.open(path)
        }
    },
    components: {
    }
}
</script>

<style>

.footer {display: flex;justify-content: center; background: #252525;color: #f1f1f1;}
.footer_item {cursor: pointer;padding: 10px 10px 10px 10px;text-align: center;}

</style>